@import "global.css";

.Header {
  background-color: var(--background-color);
  color: var(--primary-color);
  padding: 1em;
}

.Header h1 {
  font-family: var(--title-font);
  font-size: 5em;
  line-height: 0.8;
}

.Link {
  color: #fff;
  text-decoration: none;
}

@media screen and (max-width: 506px) {
  .Header h1 {
    font-size: 3em;
  }
}
