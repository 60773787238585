:root {
  --primary-color: #f4a900;
  --primary-color-hover: #ffd574;
  --background-color: #0b142f;
  --title-font: "Space Grotesk", sans-serif;
  --text-font: "Inter", sans-serif;
}

.subheader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.btn {
  padding: 0.5em 1em;
  background-color: var(--primary-color);
  color: var(--background-color);
  text-decoration: none;
  border-radius: 0.5em;
  transition: background ease 0.25s;
}

.btn:hover {
  background-color: var(--primary-color-hover);
}
