@import "styles/global.css";

.Body {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: var(--background-color);
  color: white;
  text-align: center;
  font-size: 1.1em;
  font-weight: bold;
  line-height: 1.5;
}

.Body #logo {
  vertical-align: middle;
}
