.Table {
  flex-grow: 1;
  padding: 1em;
  background-color: transparent;
}

.Abstract {
  text-align: justify;
}

.Links {
  display: flex;
  flex-direction: row;
}

.Button {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;

  text-decoration: none;
  color: initial;
}
